import {
  ICarbonEmissionsSummaryDTO,
  IDashboardTarget,
  ISettingColour,
} from "@/models/Dashboard";
import { ErrorResponse } from "@/services/axios/error";
import { DashboardAPI } from "@/services/dashboard";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

const name = "DashBoardModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class DashBoardModule extends VuexModule {
  private error = {} as ErrorResponse;
  private errorCarbonEmission = {} as ErrorResponse;
  private errorDashboardTarget = {} as ErrorResponse;
  private errorNewDashboardTarget = {} as ErrorResponse;
  private dataCarbonEmissions = {} as ICarbonEmissionsSummaryDTO;
  private dataTarget = {} as IDashboardTarget;
  private isLoadingEmissions = false;
  private currentYear = new Date().getFullYear() as string | number | Date;
  private isLoadingTarget = false;

  private errorSustainabilityAchievements = {} as ErrorResponse;
  private dataSustainabilityAchievements = {} as any;
  private isLoadingSustainabilityAchievements = false;

  private dataFilterColourSetting = {} as ISettingColour;
  private errorFilterColourSetting = {} as ErrorResponse;
  private isLoadingFilterColourSetting = false;

  isError() {
    return !isEmpty(this.error);
  }

  get dataCarbonEmissionsSummaryGetter() {
    return this.dataCarbonEmissions;
  }

  get dataScopeTwoEmissionsGetter() {
    return this.dataTarget;
  }

  get isLoadingEmissionsGetter() {
    return this.isLoadingEmissions;
  }

  @Mutation
  setLoadingDashboardTargetSummary(isLoading = false as boolean) {
    this.isLoadingEmissions = isLoading;
  }

  @Mutation
  setCarbonEmissionsSummaryError(error: ErrorResponse) {
    this.errorCarbonEmission = error;
    this.isLoadingEmissions = false;
  }

  @Mutation
  setCarbonEmissionsSummarySuccess(data: ICarbonEmissionsSummaryDTO) {
    this.dataCarbonEmissions = data;
    this.isLoadingEmissions = false;
  }

  @Action
  async fetchCarbonEmissionsSummary() {
    this.setLoadingDashboardTargetSummary(true);
    const serviceDashboardAPI = new DashboardAPI();
    const { data, error } =
      await serviceDashboardAPI.getCarbonEmissionsSummary();
    if (error) {
      this.setCarbonEmissionsSummaryError(error);
    } else {
      this.setCarbonEmissionsSummarySuccess(data);
    }
  }
  //--- Dashboard Target get ---
  get isLoadingScopeTwoEmissionsGetter() {
    return this.isLoadingEmissions;
  }

  @Mutation
  loadingDashboardTarget(isLoading = false as boolean) {
    this.isLoadingTarget = isLoading;
  }

  @Mutation
  setScopeTwoEmissionsError(error: ErrorResponse) {
    this.errorDashboardTarget = error;
    this.isLoadingTarget = false;
  }

  @Mutation
  setScopeTwoEmissionsSuccess(dataTarget: IDashboardTarget) {
    this.dataTarget = dataTarget;
    this.isLoadingTarget = false;
  }

  @Action
  async fetchScopeTwoEmissions(params: string | number | Date) {
    this.loadingDashboardTarget(true);
    const serviceDashboardAPI = new DashboardAPI();
    const { data, error } = await serviceDashboardAPI.getScopeTwoEmissions(
      params
    );
    if (error) {
      this.setScopeTwoEmissionsError(error);
    } else {
      this.setScopeTwoEmissionsSuccess(data);
    }
  }
  // Save Current Year in State
  get currentYearGetter() {
    return this.currentYear;
  }

  @Mutation
  setCurrentYearSummary(data: string | number | Date) {
    if (data instanceof Date) {
      this.currentYear = new Date(data).getFullYear();
    } else {
      this.currentYear = Number(data);
    }
  }

  @Action
  saveCurrentYearAction(data: string | number | Date) {
    this.setCurrentYearSummary(data);
  }
  //--- Dashboard Target post ---

  @Mutation
  setDashboardNewTargetError(error: ErrorResponse) {
    this.errorNewDashboardTarget = error;
    ElMessage.error(error.message);
  }

  @Mutation
  setDashboardNewTargetSuccess() {
    ElMessage.success("Save the target success.");
    this.error = {} as ErrorResponse;
  }

  @Action
  async createNewTarget(data: IDashboardTarget) {
    const serviceDashboardAPI = new DashboardAPI();
    const { error } = await serviceDashboardAPI.createNewTarget(data);
    if (error) {
      this.setDashboardNewTargetError(error);
    } else {
      this.setDashboardNewTargetSuccess();
      await this.fetchScopeTwoEmissions(data.Year);
    }
  }

  get loadingSustainabilityAchievementsGetter() {
    return this.isLoadingSustainabilityAchievements;
  }

  get errorSustainabilityAchievementsGetter() {
    return this.errorSustainabilityAchievements;
  }

  get dataSustainabilityAchievementsGetter() {
    return this.dataSustainabilityAchievements;
  }
  @Mutation
  createSustainabilityAchievements(loading = false as boolean) {
    this.isLoadingSustainabilityAchievements = loading;
  }

  @Mutation
  createSustainabilityAchievementsError(error: ErrorResponse) {
    this.errorCarbonEmission = error;
    this.isLoadingSustainabilityAchievements = false;
  }

  @Mutation
  createSustainabilityAchievementsSucess(data: any) {
    this.dataSustainabilityAchievements = data;
    this.isLoadingSustainabilityAchievements = false;
    this.errorCarbonEmission = {} as ErrorResponse;
  }
  @Action
  async createSustainabilityAchievementsAction(payload: any) {
    this.createSustainabilityAchievements(true);
    const serviceDashboardAPI = new DashboardAPI();
    const { data, error } =
      await serviceDashboardAPI.createDataSustainabilityAchievements(payload);
    if (error) {
      this.createSustainabilityAchievementsError(error);
    } else {
      this.createSustainabilityAchievementsSucess(data);
    }
  }

  @Mutation
  filterColourSettingActionLoading(loading = false as boolean) {
    this.isLoadingFilterColourSetting = loading;
  }
  @Mutation
  filterColourSettingError(error: ErrorResponse) {
    this.isLoadingFilterColourSetting = false;
    this.errorFilterColourSetting = error;
  }
  @Mutation
  filterColourSettingSuccess(data: any) {
    this.isLoadingFilterColourSetting = false;
    this.errorFilterColourSetting = {} as ErrorResponse;
    this.dataFilterColourSetting = data;
  }
  @Action
  async filterColourSettingAction(payload: ISettingColour) {
    this.filterColourSettingActionLoading(true);
    const serviceDashboardAPI = new DashboardAPI();
    const { data, error } = await serviceDashboardAPI.filterColourSetting(
      payload
    );
    if (error) {
      this.filterColourSettingError(error);
    } else {
      this.filterColourSettingSuccess(data);
    }
  }
}
export default getModule(DashBoardModule);
